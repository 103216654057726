import React from 'react';

var Content = function Content(props) {
  var overlay = props.overlay,
      prefixCls = props.prefixCls,
      id = props.id;
  return React.createElement("div", {
    className: "".concat(prefixCls, "-inner"),
    id: id,
    role: "tooltip"
  }, typeof overlay === 'function' ? overlay() : overlay);
};

export default Content;